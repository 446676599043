.container {
  width: 30% !important ;
  margin-top: 10rem !important;
  background-color: rgba(53, 53, 53, 0) !important;
}

.innerContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
  border-radius: 20px;
  background-color: #e3e3e3;
  font-family: sans-serif;
  text-align: start;
}

.innerContainer p {
  font-size: 42px;
  font-family: sans-serif;
}

.innerContainer button {
  width: 50%;
}
.inputElement {
  width: 80%;
  padding: 30px 20px;
}

.inputElement label {
  font-size: 24px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .container{
    width: 100% !important;
    margin-top: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1080px) {
  .container{
    width: 70% !important;
    margin-top: 0 !important;
  }

}