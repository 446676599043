.mainContainer{
    width: 65%;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.mainContainer h2{
    margin-bottom: 3rem;
}

.container{
    text-align: start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.container label{
    margin-bottom: 1rem;
    font-size: larger;
    font-weight: 600;
}

.statusController{
    display: flex;
    gap: 20px;
    width: 55%;
}

.statusController button{
    height: 40px;
}

.textArea{
    height: 140px;
}

.textArea input{
    height: 150px !important;
}

.statsContainer{
    width: 100%;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
}


.statsSection{
    display: flex;
    margin-top: 2rem;

    gap: 30px;
}

.listContainer{
    width: 40%;
}
.statsList{
    display: flex;
    gap: 30px;
    align-items: center;
}

.statsList li{
    margin-bottom: 1rem;
}

.statsList img{
    width: 30px;
}

.statsList span{
    width: 80%;
    text-align: start;
    word-wrap: break-word;
}