.container{
    width: 100%;
}

.tableHeading{
    white-space: nowrap;
}

.filterContainer{
    display: flex;
    align-items: center;
    gap: 30px;
}

.filterContainer button{
    background-color: transparent;
    border: 0;
}

.filterContainer button :hover{
    background-color: gray !important;
}

.filterContainer svg{
    color: black;
}

.filterContainer a{
    padding:0px;
}

.stickyHeader {
    position: sticky;
    top: 0;
}

.tableHeading th{
    padding: 3rem 2rem;
    vertical-align: middle;
    padding-top: 2rem;
}


.cell td{
    vertical-align: middle;
}

.stickyCell {
    position: sticky;
    background-color: #f8f9fa; 
    border-right-color: wheat;
    
  }
  
  .stickyLeft {
    left: 0;
  }