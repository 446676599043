.formContainer {
  display: flex;
  gap: 30px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  align-items: center;
}

.menuIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menuIcons img {
  width: 20px;
  margin-right: 20px;
  border-radius: 50%;
}

.input {
  height: 75px;
  margin-left: 10px;
}

.dataList ul {
  list-style-type: none;
}

.dataList li {
  margin-bottom: 1rem;
}
.dataList span {
  word-wrap: break-word;
}
