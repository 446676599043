.App {
  text-align: center;
  display: flex;
  justify-content: center;
}

.table-responsive{
  height: 90vh;
}

.form-floating{ 
  width: 60%;
}


.dropBtn button:hover{
  background-color: rgb(228, 228, 228) !important;
}

.dropBtn .btn.show{
  background-color: transparent;
}

.dropdown-menu{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container{
  display: flex;
 
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mb-3{
  width: 40% !important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
